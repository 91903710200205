<template>
  <div id="editExchangeActionList">
    <!-- 表单 -->
    <el-tabs v-model="activeName" @tab-click="tabClick">
      <!--------------------------------------------------------- 已提取 ------------------------------------------------------>
      <el-tab-pane label="已提取" name="1">
        <!-- 顶部搜索 -->
        <div class="header">
          <el-input
            class="w300 fr"
            placeholder="输入提取人搜索"
            v-model="formData.name"
            @input="changeForm"
          ></el-input>
        </div>
        <!-- 表格 -->
        <el-table
          :data="tableData"
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
        >
          <el-table-column label="提取人">
            <template slot-scope="scope">
              <div
                class="cp student"
                @click="touserDetail(scope.row)"
                title="点击跳转至学员详情页面"
              >
                <img
                  :src="scope.row.uphoto"
                  alt=""
                  width="28"
                  height="28"
                  style="border-radius: 50%"
                />
                <div class="ml10 student_name">
                  <span>{{ scope.row.uname }}</span>
                  <span
                    v-if="scope.row.student_remarks"
                    style="font-size: 12px"
                  >
                    {{ scope.row.student_remarks }}
                  </span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="提取时间">
            <template slot-scope="scope">
              {{ scope.row.c_time | formatTimeStamp }}
            </template>
          </el-table-column>
          <el-table-column label="操作" v-if="showRemoveExtractioncode">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                @click="voidDHM(scope.row.exchange_details_id)"
              >
                作废
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!--分页-->
        <pagination
          :currPage="formData.page"
          :total="total"
          :limit="15"
          @currentChange="pageCodeChange"
        ></pagination>
      </el-tab-pane>
      <!--------------------------------------------------------- 已作废 ------------------------------------------------------>
      <el-tab-pane label="已作废" name="2">
        <!-- 顶部搜索 -->
        <div class="header">
          <el-input
            class="w300 fr"
            placeholder="输入提取人搜索"
            v-model="formData.name"
            @input="changeForm"
          ></el-input>
        </div>
        <!-- 表格 -->
        <el-table
          :data="tableData"
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
        >
          <el-table-column label="提取人">
            <template slot-scope="scope">
              <div
                class="cp student"
                @click="touserDetail(scope.row)"
                title="点击跳转至学员详情页面"
              >
                <img
                  :src="scope.row.uphoto"
                  alt=""
                  width="28"
                  height="28"
                  style="border-radius: 50%"
                />
                <div class="student_name ml10">
                  <span>{{ scope.row.uname }}</span>
                  <span
                    v-if="scope.row.student_remarks"
                    style="font-size: 12px"
                  >
                    {{ scope.row.student_remarks }}
                  </span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="提取时间">
            <template slot-scope="scope">
              {{ scope.row.c_time | formatTimeStamp }}
            </template>
          </el-table-column>
          <el-table-column label="作废时间">
            <template slot-scope="scope">
              {{ scope.row.invalid_time | formatTimeStamp }}
            </template>
          </el-table-column>
          <el-table-column label="操作人">
            <template slot-scope="scope">
              {{ scope.row.operating_name }}
            </template>
          </el-table-column>
        </el-table>
        <!--分页-->
        <pagination
          :currPage="formData.page"
          :total="total"
          :limit="15"
          @currentChange="pageCodeChange"
        ></pagination>
      </el-tab-pane>
      <!--------------------------------------------------------- 已作废 ------------------------------------------------------>
    </el-tabs>
    <loading v-show="isLoading" leftPos="0"></loading>
    <!--End-->
  </div>
</template>
<script>
import loading from '@/components/LoadGif'
export default {
  name: 'editExchangeActionList',
  components: {
    loading,
  },
  data() {
    return {
      isLoading: false,
      // 复制兑换码弹窗
      dialogIsShow_1: false,
      copyUrl: '',
      // tabs绑定值
      activeName: '1',
      // 表单数据
      formData: {
        name: '', // 搜索内容
        page: 1, // 页码
        status: '1', // 兑换码状态
        exchange_id: '', // 兑换码id
      },
      // 表格信息
      tableData: [],
      total: 0,
    }
  },
  mounted() {
    this.$root.$children[0].childPageOptions[0].name = '提取码'
    // 获取表格数据
    this.formData.exchange_id = this.$route.query.exchange_id
    this.getTableList()
    // 改变顶部标题
    this.$root.$children[0].childPageOptions[
      this.$root.$children[0].childPageOptions.length - 1
    ].name = this.$route.query.name

    this.$parent.$parent.childPageOptions[0].prompt = ''
  },
  computed: {
    //作废提取码
    showRemoveExtractioncode() {
      return this.$store.state.roots.includes(149)
    },
  },
  methods: {
    touserDetail(row) {
      let url = this.$router.resolve({
        path: `/manageStudent/detail?uid=${row.uid}`,
      })
      this.$store.dispatch('open', url.href)
    },
    // 切换标签
    tabClick() {
      this.tableData = []
      this.formData.page = 1
      this.formData.status = this.activeName
      this.getTableList()
    },
    // 作废提取码
    voidDHM(exchange_details_id) {
      this.$confirm(
        '作废后，学员获得的课程将会失效！并且该学员占用的提取名额会被释放！',
        '作废',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      ).then(() => {
        this.$http({
          url: '/Exchange/cancelRedeemCode',
          data: {
            exchange_details_id,
            exchange_id: this.$route.query.exchange_id,
          },
          callback: () => {
            this.getTableList()
            this.$message({
              type: 'success',
              message: '作废成功!',
            })
          },
        })
      })
    },
    // 筛选表格数据
    changeForm() {
      this.formData.page = 1
      this.getTableList()
    },
    // 分页
    pageCodeChange(val) {
      this.formData.page = val
      this.getTableList()
    },
    // 获取表格数据
    getTableList() {
      this.isLoading = true
      this.$http({
        url: '/Exchange/codeDetail',
        data: this.formData,
        callback: ({ data, allNum }) => {
          this.isLoading = false
          this.total = Number(allNum)
          this.tableData = data
        },
        error: (err) => {
          this.isLoading = false
          if (err.code == 204) {
            this.total = 0
            this.tableData = []
          }
        },
      })
    },
  },
}
</script>
<style lang="less" scoped>
#editExchangeActionList {
  background: rgba(255, 255, 255, 1);
  box-sizing: border-box;
  .student {
    display: flex;
    align-items: center;
    .student_name {
      font-size: 12px;
      font-weight: 500;
      color: #333333;
      line-height: 17px;
      color: blue !important;
      display: flex;
      flex-direction: column;
    }
  }
  .el-tabs {
    /deep/ .el-tabs__content {
      .header {
        width: 100%;
        overflow: hidden;
        margin-bottom: 10px;
        .el-input {
          .el-input-group__prepend {
            background-color: #fff;
          }
        }
        .Percentage {
          width: 230px;
          display: inline-block;
          height: 100%;
          margin-left: 20px;
          color: rgba(10, 162, 155, 1);
          background: ren;
          font-size: 14px;
          p {
            height: 17px;
            line-height: 17px;
            margin-bottom: 14px;
          }
        }
      }
      .el-divider {
        background: rgba(27, 157, 151, 1);
      }
    }
  }
  .send_verification {
    /deep/ .el-dialog {
      .el-dialog__body {
        .box_info {
          height: 130px;
          margin-bottom: 20px;
          .left {
            width: 93px;
            height: 100px;
            float: left;
          }
          .right {
            width: 100%;
            // width: calc(100% - 93px);
            float: right;
            height: 100px;
            background: rgba(245, 245, 245, 1);
            box-sizing: border-box;
            padding: 17px 20px;
            font-size: 12px;
            .info_left {
              width: 500px;
              height: 100%;
              float: left;
            }
            .info_right {
              width: 100px;
              float: right;
              color: rgba(27, 157, 151, 1);
              cursor: pointer;
            }
          }
          .tip {
            text-indent: 110px;
            color: rgba(255, 53, 53, 1);
            font-size: 12px;
            height: 30px;
            line-height: 30px;
          }
        }
      }
    }
  }
}
</style>
